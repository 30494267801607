import { cva } from 'class-variance-authority';

export const buttonVariants = cva(
  'button inline-flex items-center justify-center gap-1.5 whitespace-nowrap px-2 focus-visible:outline-2 disabled:cursor-default disabled:opacity-35 disabled:outline-none',
  {
    variants: {
      variant: {
        default:
          'bg-color-grey-light enabled:hover:bg-color-grey enabled:focus-visible:bg-color-grey border-color-text-darktransparent enabled:focus-visible:outline-color-text-dark-transparent border',
        primary: 'button-primary enabled:focus-visible:outline-color-primary',
        secondary: 'button-secondary enabled:focus-visible:outline-color-secondary',
        additional:
          'bg-color-additional text-color-text-light enabled:hover:bg-color-additional-dark enabled:focus-visible:bg-color-additional-dark enabled:focus-visible:outline-color-additional',
        success:
          'bg-color-success text-color-text-light enabled:hover:bg-color-success-dark enabled:focus-visible:outline-color-success',
        warning:
          'bg-color-warning text-color-text-dark enabled:hover:bg-color-warning-dark enabled:focus-visible:outline-color-warning',
        error:
          'bg-color-error text-color-text-light enabled:focus-visible:outline-color-error enabled:hover:bg-color-error-dark',
        sale: 'bg-color-sale text-color-sale-contrasttext enabled:hover:bg-color-sale-dark enabled:focus-visible:outline-color-sale',
        link: 'text-color-text-dark underline underline-offset-2',
        'icon-flat': 'bg-color-transparent rounded-full border-none',
        'color-button':
          'enabled:hover:border-color-grey border-color-grey-light border-2 border-solid group-[.color-button-rounded]:rounded-full group-[.color-button-active]:outline group-[.color-button-active]:outline-2 group-[.color-button-active]:outline-black',
      },
      size: {
        base: '',
        small: 'text-size-body3 h-3.5 [&>[class^="icon-emp"]]:h-2.5 [&>[class^="icon-emp"]]:w-2.5',
        medium: 'text-size-body2 h-[36px] [&>[class^="icon-emp"]]:h-4 [&>[class^="icon-emp"]]:w-4',
        large: 'text-size-body1 h-[44px] [&>[class^="icon-emp"]]:h-4 [&>[class^="icon-emp"]]:w-4',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'medium',
    },
    compoundVariants: [
      {
        variant: 'color-button',
        size: 'small',
        className: 'h-[30px] min-h-[30px] w-[30px] min-w-[30px] p-0',
      },
      {
        variant: 'color-button',
        size: 'base',
        className: 'h-[36px] min-h-[36px] w-[36px] min-w-[36px] p-0',
      },
    ],
  },
);
