import { cva } from 'class-variance-authority';

export const notificationVariants = cva(
  'flex w-full items-start rounded border px-1 py-1.5 [&>[class="notification-content"]]:mt-0.5 [&>[class="notification-content"]]:flex-grow [&>span]:h-3 [&>span]:w-3 [&>span]:shrink-0',
  {
    variants: {
      severity: {
        error:
          'bg-color-error-light border-color-error [&>span]:icon-emp-error-circle [&>span]:text-color-error',
        warning:
          'bg-color-warning-light border-color-warning [&>span]:icon-emp-warning-circle [&>span]:text-color-warning',
        success:
          'bg-color-success-light border-color-success [&>span]:icon-emp-success-circle [&>span]:text-color-success',
        info: 'bg-color-info-light border-color-info [&>span]:icon-emp-info-filled [&>span]:text-color-info',
      },
      variant: {
        default: 'text-size-body1 border-0 border-t-4 [&>span]:mr-1.5',
        outline:
          'text-size-body2 border-t p-0.5 [&>[class="notification-content"]]:mt-[2px] [&>span]:mr-1 [&>span]:h-2.5 [&>span]:w-2.5',
        feedback:
          'text-size-body2 border-0 border-t-0 p-0.5 [&>[class="notification-content"]]:mt-0 [&>div]:py-0 [&>div]:pt-0 [&>span]:mr-1 [&>span]:h-2 [&>span]:w-2',
      },
    },
    defaultVariants: {
      severity: 'info',
      variant: 'outline',
    },
    compoundVariants: [
      {
        variant: 'feedback',
        severity: 'error',
        className: '[&>div]:text-color-error-dark',
      },
      {
        variant: 'feedback',
        severity: 'info',
        className: '[&>div]:text-color-info-dark',
      },
      {
        variant: 'feedback',
        severity: 'warning',
        className: '[&>div]:text-color-warning-dark-dark',
      },
      {
        variant: 'feedback',
        severity: 'success',
        className: '[&>div]:text-color-success-dark',
      },
    ],
  },
);
