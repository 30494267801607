'use client';

import * as React from 'react';
import { type VariantProps } from 'class-variance-authority';

import { buttonVariants } from './buttonVariants';
import { cn } from '../../utils/cn/cn';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const Button = ({ className, variant, size, type, children, ...props }: ButtonProps) => (
  <button
    {...props}
    type={type === 'submit' ? 'submit' : 'button'}
    className={cn(buttonVariants({ variant, size }), className)}
  >
    {children}
  </button>
);

export { Button };
